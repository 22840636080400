import type { LanguageCode } from '@vendure/core';
import { DataService } from '@vendure/admin-ui/core';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  type Observable,
  of,
  map,
  switchMap,
  distinctUntilChanged,
  shareReplay,
} from 'rxjs';
import type {
  FormInputComponent,
  InputComponentConfig,
} from '@vendure/admin-ui/core';
import { type UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { z } from 'zod';
import { v4 } from 'uuid';

type TipsList = z.infer<typeof tipsSchema>;

const tipsSchema = z.array(
  z.object({
    text: z.string(),
    icon: z.string(),
    id: z.string().uuid(),
    hideOn: z.string().optional().catch('off'),
  }),
);

@Component({
  selector: 'tips-list',
  templateUrl: './tips-list.component.html',
  styleUrls: ['./tips-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipsListComponent implements FormInputComponent, OnInit {
  static readonly id = 'tips-list';

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private sanitizer: DomSanitizer,
  ) {}

  readonly!: boolean;
  formControl!: UntypedFormControl;
  config!: InputComponentConfig;

  private $languageCode!: Observable<LanguageCode>;

  hiddenOnOptions = [
    { key: 'off', label: 'Off' },
    { key: 'mobile', label: 'Mobile' },
    { key: 'desktop', label: 'Desktop' },
  ];

  icons = [
    {
      key: 'edit',
      html: this.sanitizer.bypassSecurityTrustHtml(
        '<svg height="16" viewBox="0 0 18 18" width="16"><path clipRule=evenodd d="M16.153.247l1.6 1.6a.88.88 0 010 1.224L15.823 5 13 2.176 14.93.247a.88.88 0 011.223 0zM15 6.071L5.07 16 2 12.93 11.93 3 15 6.07zM4 16.866l-.102.051-3.48 1.075a.339.339 0 01-.41-.41l1.075-3.48.05-.102L4 16.866z"fillRule=evenodd /></svg>',
      ),
    },
    {
      key: 'crop',
      html: this.sanitizer.bypassSecurityTrustHtml(
        '<svg height=16 viewBox="0 0 512 512"width=16 xmlns=http://www.w3.org/2000/svg><path d="M128 32c0-17.7-14.3-32-32-32S64 14.3 64 32V64H32C14.3 64 0 78.3 0 96s14.3 32 32 32H64V384c0 35.3 28.7 64 64 64H352V384H128V32zM384 480c0 17.7 14.3 32 32 32s32-14.3 32-32V448h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H448l0-256c0-35.3-28.7-64-64-64L160 64v64l224 0 0 352z"/></svg>',
      ),
    },
    {
      key: 'duplicate',
      html: this.sanitizer.bypassSecurityTrustHtml(
        '<svg height=16 viewBox="0 0 512 512"width=16 xmlns=http://www.w3.org/2000/svg><path d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64 128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z"/></svg>',
      ),
    },
    {
      key: 'delete',
      html: this.sanitizer.bypassSecurityTrustHtml(
        '<svg height=16 viewBox="0 0 448 512"width=16 xmlns=http://www.w3.org/2000/svg><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>',
      ),
    },
    {
      key: 'move',
      html: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="16" height="16"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l9.4-9.4L224 224l-114.7 0 9.4-9.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4L224 288l0 114.7-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-9.4 9.4L288 288l114.7 0-9.4 9.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l9.4 9.4L288 224l0-114.7 9.4 9.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-64-64z"/></svg>',
      ),
    },
    {
      key: 'text',
      html: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M205.1 52.76C201.3 40.3 189.3 32.01 176 32.01S150.7 40.3 146 52.76l-144 384c-6.203 16.56 2.188 35 18.73 41.22c16.55 6.125 34.98-2.156 41.2-18.72l28.21-75.25h171.6l28.21 75.25C294.9 472.1 307 480 320 480c3.734 0 7.531-.6562 11.23-2.031c16.55-6.219 24.94-24.66 18.73-41.22L205.1 52.76zM114.2 320L176 155.1l61.82 164.9H114.2zM608 160c-13.14 0-24.37 7.943-29.3 19.27C559.2 167.3 536.5 160 512 160c-70.58 0-128 57.41-128 128l.0007 63.93c0 70.59 57.42 128.1 127.1 128.1c24.51 0 47.21-7.266 66.7-19.26C583.6 472.1 594.9 480 608 480c17.67 0 32-14.31 32-32V192C640 174.3 625.7 160 608 160zM576 352c0 35.28-28.7 64-64 64s-64-28.72-64-64v-64c0-35.28 28.7-63.1 64-63.1s64 28.72 64 63.1V352z"/></svg>',
      ),
    },
    {
      key: 'plus',
      html: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="16" height="16" viewBox="0 0 494.1 360.1"xmlns="http://www.w3.org/2000/svg"><path d="M316.45,0H98.89c-21.85,0-39.56,17.71-39.56,39.56v138.44c0,21.85,17.71,39.56,39.56,39.56h217.56c21.85,0,39.56-17.71,39.56-39.56V39.56c0-21.85-17.74-39.56-39.56-39.56ZM326.33,178c0,5.45-4.44,9.89-9.89,9.89h-9.89l-67.55-99.45c-1.85-2.72-4.94-4.39-8.28-4.39s-6.4,1.65-8.23,4.4l-38.78,58.17-13.81-18.93c-1.85-2.6-4.82-4.08-7.97-4.08s-6.13,1.51-7.99,4.06l-45.16,60.21h-9.89c-5.45,0-9.89-4.44-9.89-9.89V39.56c0-5.45,4.44-9.89,9.89-9.89h217.56c5.45,0,9.89,4.44,9.89,9.89v138.44ZM138.44,49.44c-10.92,0-19.78,8.86-19.78,19.78s8.86,19.78,19.78,19.78,19.78-8.86,19.78-19.78-8.84-19.78-19.78-19.78ZM281.83,276.89H74.17C33.27,276.89,0,243.64,0,202.72V54.39c0-8.16,6.64-14.83,14.83-14.83s14.83,6.68,14.83,14.83v148.33c0,24.54,19.96,44.5,44.5,44.5h207.67c8.19,0,14.83,6.64,14.83,14.83s-6.61,14.83-14.83,14.83Z"/><path d="M451.72,261.27c0,3.68-2.96,6.64-6.64,6.64h-44.25v44.25c0,3.66-2.97,6.64-6.64,6.64s-6.64-2.96-6.64-6.64v-44.25h-44.25c-3.66,0-6.64-2.97-6.64-6.63s2.97-6.64,6.64-6.64h44.25v-44.25c0-3.66,2.97-6.63,6.64-6.63s6.64,2.97,6.64,6.63v44.25h44.25c3.68,0,6.64,2.99,6.64,6.64Z"/><circle className="stroke-petrol"cx="394.77"cy="260.77"fill="none"r="86.33"strokeMiterlimit="10"strokeWidth="26"/></svg>',
      ),
    },
    {
      key: 'warning',
      html: this.sanitizer.bypassSecurityTrustHtml(
        '<svg height="16"viewBox="0 0 512 512"width="16"xmlns="http://www.w3.org/2000/svg"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>',
      ),
    },
  ];

  $tips: TipsList = [];

  ngOnInit() {
    this.listenToLanguageChange();
    this.getTips();
  }

  protected getTips() {
    try {
      const value = this.formControl.value;
      if (value) {
        const { data } = tipsSchema.safeParse(JSON.parse(value));
        if (data) {
          this.$tips = data;
        }
      } else {
        this.$tips = [];
      }
    } catch (err) {
      console.error(err);
    }
  }

  protected listenToLanguageChange() {
    this.$languageCode = this.route.paramMap.pipe(
      map((paramMap) => paramMap.get('lang')),
      switchMap((lang) => {
        if (lang) {
          return of(lang as LanguageCode);
        } else {
          return this.dataService.client
            .uiState()
            .mapSingle((data) => data.uiState.contentLanguage);
        }
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.$languageCode.subscribe(() => {
      this.getTips();
    });
  }

  addTip() {
    this.$tips = [
      ...this.$tips,
      { hideOn: 'off', id: v4(), text: '', icon: '' },
    ];
    this.formControl.setValue(JSON.stringify(this.$tips));
  }

  updateTip(tip: TipsList[number]) {
    this.$tips = this.$tips.map((t) => (t === tip ? tip : t));

    this.formControl.setValue(JSON.stringify(this.$tips));
    this.formControl.markAsDirty();
  }

  removeTip(tip: TipsList[number]) {
    this.$tips = this.$tips.filter((t) => t.id !== tip.id);
    this.formControl.setValue(JSON.stringify(this.$tips));
    this.formControl.markAsDirty();
  }
}
