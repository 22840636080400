<div class="tips-container">
  <button class="add-tips-button button primary" (click)="addTip()">
    Add tip
  </button>

  <div *ngIf="!$tips.length" class="no-tips">
    <clr-icon shape="info-circle" size="24"></clr-icon>
    <span>There are no tips for this product (variant) in this language.</span>
  </div>

  <ul class="tip-list" *ngFor="let tip of $tips">
    <li class="tip">
      <div>
        <label>Icon</label>
        <ng-select
          id="icon-select"
          [items]="icons"
          appendTo="body"
          [disabled]="readonly"
          bindLabel="html"
          bindValue="key"
          [multiple]="false"
          [(ngModel)]="tip.icon"
          (change)="updateTip(tip)"
        >
          <ng-template ng-label-tmp let-item="item">
            <div [innerHTML]="item.html"></div>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <div [innerHTML]="item.html"></div>
          </ng-template>
        </ng-select>
      </div>

      <div>
        <label>Hide on</label>
        <ng-select
          id="hide-on-select"
          [items]="hiddenOnOptions"
          appendTo="body"
          [disabled]="readonly"
          [(ngModel)]="tip.hideOn"
          bindLabel="label"
          bindValue="key"
          [multiple]="false"
          (change)="updateTip(tip)"
        >
        </ng-select>
      </div>

      <input
        (change)="updateTip(tip)"
        type="text"
        placeholder="Write a tip..."
        [(ngModel)]="tip.text"
      />

      <button (click)="removeTip(tip)" class="button danger">
        <clr-icon shape="trash" size="16"></clr-icon>
      </button>
    </li>
  </ul>
</div>
