import { NgModule } from '@angular/core';
import {
  SharedModule,
  registerFormInputComponent,
} from '@vendure/admin-ui/core';
import { TipsListComponent } from './components/tips-list/tips-list.component';

@NgModule({
  imports: [SharedModule],
  providers: [registerFormInputComponent('tips-list', TipsListComponent)],
  declarations: [TipsListComponent],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SharedUIModuleProducts {}
